var merge = require('lodash/merge')
var prodEnv = require('./prod.env')

module.exports = merge(prodEnv, {
  env: 'development',
  apiUrl: 'http://localhost:8080', // Local seulement,
  webSocketConnectedMediumsEndpoint: "ws://localhost:8080/ws/v1/mediums/connected",
  authConfig: {
    devMode: true
  }
})

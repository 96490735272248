import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import config from './config'
import axios from 'axios'
import './plugins/vue-moment'

import YfxCloudAuth from '@yodatech/vue-yfx-cloud-auth'
import YfxLicense from '@yodatech/vue-yfx-license'

Vue.use(YfxCloudAuth, {serviceName: "display-api", store: store, axios: axios, ...config.authConfig})
Vue.use(YfxLicense, {axios: axios})

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$config = config;

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

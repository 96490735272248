import Vue from 'vue'
import Vuex from 'vuex'
import {subscribeToConnectedMediums} from "@/business/connected-mediums-business";

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    connectedSlugs: []
  },
  getters: {
  },
  mutations: {
    setConnectedSlugs(state, slugs) {
      state.connectedSlugs = slugs;
    }
  },
  actions: {
  },
  modules: {
  }
})
subscribeToConnectedMediums((slugs=>store.commit('setConnectedSlugs', slugs)), ()=>{});

export default store;

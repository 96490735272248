var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"d-flex flex-row align-end"},[_c('v-icon',[_vm._v("search")]),_c('y-company-select',{model:{value:(_vm.filters.company),callback:function ($$v) {_vm.$set(_vm.filters, "company", $$v)},expression:"filters.company"}})],1),_c('v-data-table',{attrs:{"footer-props":{'items-per-page-options': [10, 20, 50, 100]},"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){return _vm.$emit('display-selected', $event)}},scopedSlots:_vm._u([{key:"item.companyUuid",fn:function(ref){
var item = ref.item;
return [_c('y-company-label',{attrs:{"value":item.companyUuid,"copy-on-click":false,"show-tooltip-only-on-error":""},on:{"click":_vm.onCompanyClick}})]}},{key:"item.branchUuid",fn:function(ref){
var item = ref.item;
return [(item.branchUuid && item.branchUuid.length === 32)?_c('y-company-label',{attrs:{"value":item.branchUuid,"copy-on-click":false,"show-tooltip-only-on-error":""},on:{"click":_vm.onCompanyClick}}):_c('span',[_vm._v(_vm._s(item.branchUuid))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.labelForTypeEnum(item.type)))])]}},{key:"item.mediums",fn:function(ref){
var item = ref.item;
return _vm._l((item.mediums),function(m,i){return _c('display-medium-type-chip',{key:i,staticClass:"mr-1",attrs:{"value":m}})})}},{key:"item.actions",fn:function(ref){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]}}],null,true)},[_vm._v(" > ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-chip dark :color="value.type | colorForMediumType" @click="goToConfig">
    <v-icon small class="mr-1" color="light-green accent-3" v-if="connectedSlugs.indexOf(value.slug) >= 0">cast_connected</v-icon>
    <v-icon small class="mr-1" color="amber" v-else-if="daysSinceLastConnection > 15">mdi-ghost</v-icon>
    <v-icon small class="mr-1" color="cyan accent-1" v-else-if="value.lastConnectionDate == null">mdi-link-variant-remove</v-icon>
    <v-icon small class="mr-1" color="grey lighten-1" v-else>mdi-cast-off</v-icon>
    {{ value.type | formatMediumType }}
  </v-chip>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DisplayMediumTypeChip",
  props: {
    value: Object
  },
  computed: {
    ...mapState(['connectedSlugs']),
    daysSinceLastConnection() {
      return this.value.lastConnectionDate == null ? null :
          (new Date().getTime() - new Date(this.value.lastConnectionDate).getTime()) / 86400000;
    }
  },
  methods: {
    goToConfig() {
      window.open(this.value.creatorUrl, '_blank');
    }
  },
  filters: {
    formatMediumType(t) {
      switch (t) {
        case "PI_V1":
          return "Pi v1";
        case "PI_V2":
          return "Pi v2";
        case "ANDROID_TV_BOX_V1":
          return "TV Box";
        case "URL_V1":
          return "URL";
        default:
          return "N/A";
      }
    },
    colorForMediumType(t) {
      switch (t) {
        case "PI_V1":
          return "cyan darken-2";
        case "PI_V2":
          return "cyan darken-4";
        case "ANDROID_TV_BOX_V1":
          return "light-green darken-2";
        case "URL_V1":
          return "pink darken-2";
        default:
          return "";
      }
    }
  },
}
</script>

<style scoped>

</style>

import config from "@/config";
export function subscribeToConnectedMediums(dataCallback, statusCallback)
{
	const ws = new WebSocket(config.webSocketConnectedMediumsEndpoint);

	ws.addEventListener('open', (e) => {
		console.log("Connection opened...", e)
		statusCallback(true);
	})

	ws.addEventListener('message', (e) => {
		dataCallback(JSON.parse(e.data).payload);
	})

	ws.addEventListener('error', (e) => {
		console.log("Connection error... trying to reopen in 10 seconds", e)
		statusCallback(false);
		ws.close();
	})

	ws.addEventListener('close', () => {
		console.log("Connection closed... trying to reopen in 5 seconds")
		statusCallback(false);
		// On retente la connexion automatiquement apres fermeture
		setTimeout(() => subscribeToConnectedMediums(dataCallback, statusCallback), 5000);
	})

}

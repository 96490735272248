<template>
  <v-card>

    <v-card-title>
      <y-company-label :value="value.companyUuid" :copy-on-click="false" :show-tooltip="false"/>
    </v-card-title>

    <v-card-subtitle>{{ value.branchUuid }}
      <span v-if="value.name">&nbsp;— {{ value.name }}</span>
    </v-card-subtitle>


    <v-card-subtitle>Données et mise à jour</v-card-subtitle>
    <v-list two-line flat>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Dernière mise à jour des données Display</v-list-item-title>
          <v-list-item-subtitle>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ value.displayDataUpdatedAt | moment('from') }}</span>
              </template>
              <span>{{ value.displayDataUpdatedAt | moment('LLLL') }}</span>
            </v-tooltip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Type de mise à jour</v-list-item-title>
          <v-list-item-subtitle>
            <span>{{
                value.displayDataUpdatedBy === 'legacy-update-loop'
                    ? 'Récupération depuis la v1' : 'Synchronisation directe v2'
              }}</span>
            <span>&nbsp;—&nbsp;</span>
            <a href="#" v-bind:class="{'y-disabled-link': forceRefresh.loading}" @click.prevent="forceRefreshHasBeenClicked">Forcer une récupération depuis l'API v1</a>
            <v-progress-circular class="ml-2" size="15" v-if="forceRefresh.loading" indeterminate/>
            <v-snackbar top color="error" v-model="forceRefresh.error" :timeout="5000">Une erreur est survenue lors de la demande de rafraichissement des données.</v-snackbar>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Créé le</v-list-item-title>
          <v-list-item-subtitle>{{ value.createdAt | moment('LLLL') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-subtitle class="mt-2">Medium<span v-if="value.mediums.length > 1">s</span></v-card-subtitle>
    <v-list three-line flat>
      <v-list-item v-for="(m,i) of value.mediums" :key="i">
        <v-list-item-content>
          <v-list-item-title>
            <display-medium-type-chip :value="m" class="mr-2"/>
            {{ m.name }}
            <pre class="ml-2 d-inline-block">{{ m.slug }}</pre>
          </v-list-item-title>
          <v-list-item-subtitle>
            Créé le {{ m.createdAt | moment('LLLL') }}
            <span v-if="m.createdAt !== m.updatedAt">&nbsp;— mis à jour le {{ m.updatedAt | moment('LLLL') }}</span>
            <span v-if="connectedSlugs.indexOf(m.slug) < 0">&nbsp;— dernière connexion
              <v-tooltip bottom v-if="m.lastConnectionDate">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ m.lastConnectionDate | moment('from') }}</span>
                </template>
                <span>{{ value.displayDataUpdatedAt | moment('LLLL') }}</span>
              </v-tooltip>
              <span v-else>: jamais</span>
              </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
              <a :href="m.creatorUrl" target="_blank">Accéder à l'interface de configuration</a>
              <span v-if="connectedSlugs.indexOf(m.slug) >= 0">&nbsp;—&nbsp;<a href="#" @click.prevent="forceReloadDisplayHasBeenClicked(m)">Forcer un redémarrage</a></span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </v-card>
</template>

<script>
import DisplayMediumTypeChip from "@/components/DisplayMediumTypeChip";
import {mapState} from "vuex";

export default {
  name: "DisplayDetailCard",
  components: {DisplayMediumTypeChip},
  data: () => ({
    forceRefresh: {
      loading: false,
      error: false
    }
  }),
  computed: {
    ...mapState(['connectedSlugs']),
  },
  watch: {
    connectedSlugs(newVal, oldVal) {
      if (oldVal.indexOf(this.value.slug) >= 0 && newVal.indexOf(this.value.slug) < 0) { // Si ce display vient de se déconnecter
        // on refresh pour récupérer le nouveau statut
      }
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  methods: {
    forceRefreshHasBeenClicked() {
      this.forceRefresh.loading = true;
      this.forceRefresh.error = false;
      this.$http.post(this.$config.apiUrl + '/v1/displays/' + this.value.id + '/forceLegacyDataRefresh')
          .then((r)=> this.$emit('updated', r.data))
          .catch(() => this.forceRefresh.error = true)
          .finally(() => this.forceRefresh.loading = false)
    },
    forceReloadDisplayHasBeenClicked(medium) {
      this.$http.post(this.$config.apiUrl + '/v1/displays/' + this.value.id + '/mediums/'+medium.slug+'/force-reload');
    }
  }
}
</script>

<style scoped>
.y-disabled-link {
  color: grey;
  pointer-events: none;
}
</style>

<template>
  <v-container>
    <v-col>
      <div v-if="Array.isArray(connectedSlugs)" class="text-right caption grey--text mb-1">{{ connectedSlugs.length }} medium<span v-if="connectedSlugs > 1">s</span> connecté<span v-if="connectedSlugs > 1">s</span></div>
      <v-card>
        <display-table @display-selected="selectedDisplay = $event"/>
      </v-card>
    </v-col>
    <v-col v-if="selectedDisplay">
      <display-detail-card :value="selectedDisplay" @updated="selectedDisplay = $event"/>
    </v-col>
  </v-container>
</template>

<script>
import DisplayTable from "@/components/DisplayTable";
import DisplayDetailCard from "@/components/DisplayDetailCard";
import {mapState} from "vuex";

export default {
  name: "HomeView",
  components: {DisplayDetailCard, DisplayTable},
  data: () => ({
    selectedDisplay: null
  }),
  mounted() {
    let selectedDisplayId = this.$route.query.id;
    if (selectedDisplayId) {
      this.$http.get(this.$config.apiUrl + "/v1/display/" + selectedDisplayId).then(d => this.selectedDisplay = d.data);
    }
  },
  watch: {
    selectedDisplay(v) {
      history.pushState(null, null, "?id=" + v.id);
    }
  },
  computed: {
    ...mapState(["connectedSlugs"]),
  }
}
</script>

<style scoped>

</style>
